import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import Swiper from "react-id-swiper"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import SkuTile from "../../components/SkuTile"

import styles from "./minty.module.scss"

const Minty = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
      "minty-ballerina-10-satin-flats",
      "minty-ballerina-10-patent-flats",
      "minty-mary-jane-10-patent-flats",
      "minty-mary-jane-10-satin-flats",
    ].filter(handle => nodes.find(style => style.handle === handle))
      .map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        sku.handle = style.handle
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  const swiperParams = {
    containerClass: `${styles.swiperContainer} swiper-container`,
    WrapperEl: "ol",
    threshold: 10,
    spaceBetween: 0,
    slidesPerView: 1,
    loop: true,
    pagination: {
      el: `.swiper-pagination`,
      clickable: true,
    },
    // allowTouchMove: false,
    on: {
      slideChangeTransitionEnd: function() {
        if (this) {
          const i = this.activeIndex
          const video = this.slides[i].querySelector("video")
          if (video) {
            video.play()
            video.muted = true
          }
        }
      },
    },
  }

  const [swiper1, getSwiper1] = useState(null)
  const swiperParams1 = {
    ...swiperParams,
    getSwiper: getSwiper1,
  }

  const [swiper2, getSwiper2] = useState(null)
  const swiperParams2 = {
    ...swiperParams,
    getSwiper: getSwiper2,
  }

  const [swiper3, getSwiper3] = useState(null)
  const swiperParams3 = {
    ...swiperParams,
    getSwiper: getSwiper3,
  }

  const [swiper4, getSwiper4] = useState(null)
  const swiperParams4 = {
    ...swiperParams,
    getSwiper: getSwiper4,
  }

  const [swiper5, getSwiper5] = useState(null)
  const swiperParams5 = {
    ...swiperParams,
    getSwiper: getSwiper5,
  }

  const videoEl1 = useRef(null)

  const handleVideoClick = (videoEl) => {
    if (videoEl.current.paused) {
      videoEl.current.play()
      videoEl.current.setAttribute("data-playing", "true")
    } else {
      videoEl.current.pause()
      videoEl.current.setAttribute("data-playing", "false")
    }
  }

  useEffect(() => {
    videoEl1.current.addEventListener("click", () => handleVideoClick(videoEl1), true)
    return (
      videoEl1.current.removeEventListener("click", handleVideoClick)
    )
  }, [videoEl1])

  // Mute all carousel videos
  useEffect(() => {
    const videos = document.querySelectorAll("video:not([data-playing])")
    videos.forEach(video => {
      video.muted = true
    })
  })

  return (
    <div className={styles.ss22}>
      <SEO
        title="Minty Mellon Collection"
        description="New Collection"
        url={location.pathname}
      />

      <section className={styles.moduleVideo1}>
        { // eslint-disable-next-line jsx-a11y/media-has-caption
        <video playsInline poster="https://cdn.shopify.com/s/files/1/1103/4464/files/MM_hero_video_cover.jpg?v=1683851881" className={styles.video} ref={videoEl1} data-playing="false">
          <source
            src="https://cdn.shopify.com/videos/c/o/v/3c2c0b535aaa4f0e9d05d1ea1cd519ef.mp4"
            type="video/mp4" />
        </video>
        }
        <button className={styles.playBtn} aria-label="Play" />
      </section>

      <header className={styles.header}>
        <Heading
          tag="h1"
          level="1"
          className={styles.heading}
        >
          MINTY MELLON
        </Heading>
        <p className={styles.text}>
          For Spring/Summer 2023, Tamara partnered with her daughter Araminta ‘Minty’ Mellon to design a capsule collection of ballet flats. Minty designed two styles: the Minty Ballerina and Minty Mary Jane, both of which come in a variety of colorways made from Italian patent leather and soft satin.
        </p>
      </header>

      {typeof window !== "undefined" && window.outerWidth >= 1024 &&
      <>
        <ul className={styles.grid1}>
          {allSkus.filter(s => s.handle === "minty-ballerina-10-satin-flats" && s.color === "Pink")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-ballerina-10-satin-flats" && s.color === "Brown")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-ballerina-10-satin-flats" && s.color === "Light Blue")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
        </ul>

        <section className={styles.moduleGallery}>
          <Swiper {...swiperParams1}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_1A_2000x.jpg?v=1683597590",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_1A_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_1B_2000x.jpg?v=1683597589",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_1B_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_1C_2000x.jpg?v=1683597589",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_1C_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={4}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_1D_2000x.jpg?v=1683597589",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_1D_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper1.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper1.slideNext()}
            aria-label="Next"
          />
        </section>

        <ul className={styles.grid2}>
          {allSkus.filter(s => s.handle === "minty-ballerina-10-satin-flats" && s.color === "Navy")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          <li className={styles.gridItem}>
            <Swiper {...swiperParams2}>
              <li
                key={1}
                className={styles.swiperSlide}
              >
                {// eslint-disable-next-line jsx-a11y/media-has-caption
                }
                <video autoPlay loop muted playsInline>
                  <source
                    src="https://cdn.shopify.com/videos/c/o/v/7af8d9887ec94bd48f7999b87004a097.mp4"
                    type="video/mp4" />
                </video>
              </li>
              <li
                key={2}
                className={styles.swiperSlide}
              >
                {// eslint-disable-next-line jsx-a11y/media-has-caption
                }
                <video autoPlay loop muted playsInline>
                  <source
                    src="https://cdn.shopify.com/videos/c/o/v/3d866abfe3ab445cb835d9a520f21146.mp4"
                    type="video/mp4" />
                </video>
              </li>
              <li
                key={3}
                className={styles.swiperSlide}
              >
                {// eslint-disable-next-line jsx-a11y/media-has-caption
                }
                <video autoPlay loop muted playsInline>
                  <source
                    src="https://cdn.shopify.com/videos/c/o/v/872c41914f3a4838be6798b2677bef57.mp4"
                    type="video/mp4" />
                </video>
              </li>
            </Swiper>
            <button
              className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
              onClick={() => swiper2.slidePrev()}
              aria-label="Previous"
            />
            <button
              className={`${styles.swiperButtonNext} swiper-navigation-next`}
              onClick={() => swiper2.slideNext()}
              aria-label="Next"
            />
          </li>
        </ul>

        <section className={styles.moduleGallery}>
          <Swiper {...swiperParams3}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_2A_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_2A_850x.jpg?v=1683597723",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_2B_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_2B_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_2C_2000x.jpg?v=1683597589",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_2C_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={4}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_2D_2000x.jpg?v=1683597589",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_2D_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper3.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper3.slideNext()}
            aria-label="Next"
          />
        </section>

        <ul className={styles.grid1}>
          {allSkus.filter(s => s.handle === "minty-ballerina-10-patent-flats" && s.color === "Ivory")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-ballerina-10-patent-flats" && s.color === "Black")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-ballerina-10-patent-flats" && s.color === "Red")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
        </ul>

        <ul className={styles.grid2}>
          <li className={styles.gridItem}>
            <Swiper {...swiperParams4}>
              <li
                key={1}
                className={styles.swiperSlide}
              >
                {// eslint-disable-next-line jsx-a11y/media-has-caption
                }
                <video autoPlay loop muted playsInline>
                  <source
                    src="https://cdn.shopify.com/videos/c/o/v/0aeedd4c29a64cfeb0d5f9d21af5bb83.mp4"
                    type="video/mp4" />
                </video>
              </li>
              <li
                key={2}
                className={styles.swiperSlide}
              >
                {// eslint-disable-next-line jsx-a11y/media-has-caption
                }
                <video autoPlay loop muted playsInline>
                  <source
                    src="https://cdn.shopify.com/videos/c/o/v/e6869ccd1e6143e0a7c913b55a280e56.mp4"
                    type="video/mp4" />
                </video>
              </li>
              <li
                key={3}
                className={styles.swiperSlide}
              >
                {// eslint-disable-next-line jsx-a11y/media-has-caption
                }
                <video autoPlay loop muted playsInline>
                  <source
                    src="https://cdn.shopify.com/videos/c/o/v/c3d75f0c3bcd4ba584be7f03d1615b70.mp4"
                    type="video/mp4" />
                </video>
              </li>
            </Swiper>
            <button
              className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
              onClick={() => swiper4.slidePrev()}
              aria-label="Previous"
            />
            <button
              className={`${styles.swiperButtonNext} swiper-navigation-next`}
              onClick={() => swiper4.slideNext()}
              aria-label="Next"
            />
          </li>
          {allSkus.filter(s => s.handle === "minty-mary-jane-10-patent-flats" && s.color === "Black")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
        </ul>

        <ul className={styles.grid1}>
          {allSkus.filter(s => s.handle === "minty-mary-jane-10-patent-flats" && s.color === "Ivory")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-mary-jane-10-patent-flats" && s.color === "Red")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-mary-jane-10-satin-flats" && s.color === "Navy")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
        </ul>

        <section className={styles.moduleGallery}>
          <Swiper {...swiperParams5}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_3A_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_3A_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_3B_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_3B_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_3C_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_3C_850x.jpg?v=1683597723",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={4}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_3D_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_3D_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper5.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper5.slideNext()}
            aria-label="Next"
          />
        </section>

        <ul className={styles.grid2}>
          {allSkus.filter(s => s.handle === "minty-mary-jane-10-satin-flats" && s.color === "Brown")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          <li className={styles.gridItemText}>
            <p>"This collection has been very personal to me. I chose to create a line of Ballet Flats and Mary Jane's because that is what I have wanted to wear over the past year. I feel like we have been stuck in a cycle of sneakers and boots, and I wanted to create something that could be a happy medium while still feeling a little more dressed up."</p>
          </li>
        </ul>
      </>
      }

      {typeof window !== "undefined" && window.outerWidth < 1024 &&
      <>
        <ul className={styles.grid1}>
          {allSkus.filter(s => s.handle === "minty-ballerina-10-satin-flats" && s.color === "Pink")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-ballerina-10-satin-flats" && s.color === "Brown")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
        </ul>

        <section className={styles.moduleGallery}>
          <Swiper {...swiperParams1}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_1A_2000x.jpg?v=1683597590",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_1A_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_1B_2000x.jpg?v=1683597589",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_1B_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_1C_2000x.jpg?v=1683597589",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_1C_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={4}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_1D_2000x.jpg?v=1683597589",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_1D_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
          </Swiper>
        </section>

        <ul className={styles.grid1}>
          {allSkus.filter(s => s.handle === "minty-ballerina-10-satin-flats" && s.color === "Light Blue")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-ballerina-10-satin-flats" && s.color === "Navy")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
        </ul>

        <section className={styles.moduleVideo1}>
          <Swiper {...swiperParams2}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/7af8d9887ec94bd48f7999b87004a097.mp4"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/3d866abfe3ab445cb835d9a520f21146.mp4"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/872c41914f3a4838be6798b2677bef57.mp4"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
        </section>

        <ul className={styles.grid1}>
          {allSkus.filter(s => s.handle === "minty-ballerina-10-patent-flats" && s.color === "Ivory")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-ballerina-10-patent-flats" && s.color === "Black")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-ballerina-10-patent-flats" && s.color === "Red")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          <li className={styles.gridItemText}>
            <p></p>
          </li>
        </ul>

        <section className={styles.moduleGallery}>
          <Swiper {...swiperParams3}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_2A_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_2A_850x.jpg?v=1683597723",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_2B_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_2B_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_2C_2000x.jpg?v=1683597589",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_2C_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={4}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_2D_2000x.jpg?v=1683597589",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_2D_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
          </Swiper>
        </section>

        <ul className={styles.grid1}>
          {allSkus.filter(s => s.handle === "minty-mary-jane-10-patent-flats" && s.color === "Black")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-mary-jane-10-patent-flats" && s.color === "Ivory")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
        </ul>

        <section className={styles.moduleVideo1}>
          <Swiper {...swiperParams4}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/0aeedd4c29a64cfeb0d5f9d21af5bb83.mp4"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/e6869ccd1e6143e0a7c913b55a280e56.mp4"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/c3d75f0c3bcd4ba584be7f03d1615b70.mp4"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
        </section>

        <ul className={styles.grid1}>
          {allSkus.filter(s => s.handle === "minty-mary-jane-10-patent-flats" && s.color === "Red")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-mary-jane-10-satin-flats" && s.color === "Navy")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          {allSkus.filter(s => s.handle === "minty-mary-jane-10-satin-flats" && s.color === "Brown")
            .map((sku, i) => (
              <SkuTile
                key={i}
                index={i}
                style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
                sku={sku}
                customStyles={styles}
                origin={`collection-minty`}
              />
            ))}
          <li className={styles.gridItemText}>
            <p> </p>
          </li>
        </ul>

        <section className={styles.moduleGallery}>
          <Swiper {...swiperParams5}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_3A_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_3A_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_3B_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_3B_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_3C_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_3C_850x.jpg?v=1683597723",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
            <li
              key={4}
              className={styles.swiperSlide}
            >
              <Image
                image={{
                  desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_desktop_carousel_3D_2000x.jpg?v=1683597591",
                  mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/minty_lp_mobile_carousel_3D_850x.jpg?v=1683597722",
                }}
                altText="Minty Mellon Image"
                className={styles.imgFullWidth}
              />
            </li>
          </Swiper>
        </section>

        <section className={styles.header}>
          <p className={styles.text}>
            "This collection has been very personal to me. I chose to create a line of Ballet Flats and Mary Jane's because that is what I have wanted to wear over the past year. I feel like we have been stuck in a cycle of sneakers and boots, and I wanted to create something that could be a happy medium while still feeling a little more dressed up."
          </p>
        </section>
      </>
      }

    </div>
  )
}

export default Minty

export const query = graphql`
    query MintyQuery {
        allProductStyle(filter: {handle: {in: [
            "minty-ballerina-10-patent-flats",
            "minty-ballerina-10-satin-flats",
            "minty-mary-jane-10-patent-flats",
            "minty-mary-jane-10-satin-flats",
        ]}}) {
            nodes {
                ...PLPStyleFragment
                handle
            }
        }
    }
`
